@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html,
body,
#root {
  height: 100%;
}

.euda {
  height: 100%;
  flex: 1;
  margin: 0 auto;
  display: flex;
}
.timer {
  margin: 0 auto;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  position: absolute;
  height: 100%;
  padding: 0 0 100 100;
  font-size: 5em;
  color: white;
  justify-content: center;
  width: 100%;
  text-shadow: 3px 3px 2px #393737;
  font-family: "Source Sans Pro";
}

.App {
  background-color: #282c34;
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 100%;
}
